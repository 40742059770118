import { gql, useQuery } from '@apollo/client';
import { LOGIN_VIA_SMS_APP_PERMISSION } from 'poly-security/src/permissions.js';
import { hasUserPermissionsWithAits } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';

const USER_ACCESS_ITEMS_QUERY = gql`
  query USER_SETTINGS($id: ID!) {
    user(id: $id) {
      _id
      accessItems {
        permission
        types {
          clientId {
            equals
            flippedContains
          }
          accessRoleId {
            equals
            flippedContains
          }
          userGroupId {
            equals
            flippedContains
          }
          privateSupplier {
            equals
          }
        }
      }
    }
  }
`;

export const useUserHasPermissionToLoginViaSms = (id) => {
  const { data } = useQuery(USER_ACCESS_ITEMS_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  return hasUserPermissionsWithAits(
    data?.user,
    [LOGIN_VIA_SMS_APP_PERMISSION],
    null,
  );
};
