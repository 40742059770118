import * as R from 'ramda';
import { formatTotal, removePropDeep } from 'poly-utils';
import {
  UpdateTypes,
  ProjectType,
  ClientAppNames,
  SupplierSources,
  filesCollections,
  RecurringProjectTypes,
} from 'poly-constants';

const UserGroupAIT_UIValuesUI = {
  [ClientAppNames.SITE]: 'Site',
  [ClientAppNames.ADMIN]: 'Admin',
  [ClientAppNames.MOBILE]: 'Mobile',
  [ClientAppNames.TIAA_PORTAL]: 'TIAA Portal',

  [UpdateTypes.AUDIT]: 'Audit',
  [UpdateTypes.INTERNAL]: 'Internal',
  [UpdateTypes.CLIENT_UPDATE]: 'Client Update',
  [UpdateTypes.CLIENT_REPORT]: 'Client Report',

  [ProjectType.FEE]: 'FEE',
  [ProjectType.BID]: 'BID',
  [ProjectType.LISTING]: 'Listing',
  [ProjectType.WORK_ORDER]: 'T&M',
  [ProjectType.REPORT_ONLY]: 'Report Only',
  [ProjectType.HOUSEKEEPING]: 'Housekeeping',
  [ProjectType.PASS_THROUGH]: 'Pass Through',
  [ProjectType.PREVENTIVE_MAINTENANCE]: 'Preventive Maintenance',

  [RecurringProjectTypes.recurringProject]: 'Recurring Project',
  [RecurringProjectTypes.preventiveMaintenanceProject]:
    'Preventive Maintenance',

  [SupplierSources.AAC]: 'AAC',
  [SupplierSources.TIAA]: 'TIAA',
  [SupplierSources.Eurest]: 'Eurest',

  [filesCollections.USERS]: 'Users',
  [filesCollections.ASSETS]: 'Assets',
  [filesCollections.BATCHES]: 'Batches',
  [filesCollections.CLIENTS]: 'Clients',
  [filesCollections.INVOICES]: 'Invoices',
  [filesCollections.PROJECTS]: 'Projects',
  [filesCollections.SUPPLIERS]: 'Suppliers',
  [filesCollections.PROPERTIES]: 'Properties',
  [filesCollections.OCR_INVOICES]: 'OCR Invoices',
  [filesCollections.PURCHASE_ORDERS]: 'Purchase Orders',
  [filesCollections.MASTER_SUPPLIERS]: 'Master Suppliers',
  [filesCollections.MAINTENANCE_PLANS]: 'Maintenance Plans',
  [filesCollections.RECURRING_PROJECTS]: 'Recurring Projects',
  [filesCollections.ADMIN_PURCHASE_ORDERS]: 'Admin Purchase Orders',
};

// handleValues :: { values: [String|Number] } -> String
const handleValues = R.compose(
  R.join(', '),
  R.map(R.when(R.is(Number), formatTotal)),
  R.map(R.when(R.is(String), R.prop(R.__, UserGroupAIT_UIValuesUI))),
  R.propOr([], 'values'),
);

// getConditionByPath :: [String] -> Pair (() -> Boolean) (() -> String)
const getConditionByPath = (path) => [R.hasPath(path), R.path(path)];

// handleDocuments :: { documents: [Object] } -> String
const handleDocuments = R.compose(
  R.join(', '),
  R.map(
    R.cond([
      getConditionByPath(['profile', 'fullName']),
      getConditionByPath(['company', 'name']),
      getConditionByPath(['projectId']),
      getConditionByPath(['displayName']),
      [R.T, R.prop('name')],
    ]),
  ),
  R.propOr([], 'documents'),
);

// prepareScopeValue :: [UserGroupAITypeUIAccessRole] -> [String]
const prepareScopeValue = R.ifElse(
  R.pathSatisfies(R.is(String), ['variable', 'id']),
  R.applySpec({
    isVariable: R.T,
    value: R.compose(
      R.concat(R.__, '}]'),
      R.concat('[{'),
      R.path(['variable', 'name']),
    ),
  }),
  R.compose(
    R.objOf('value'),
    R.join(' | '),
    R.juxt([
      R.prop('scope'),
      R.prop('operation'),
      R.ifElse(R.has('values'), handleValues, handleDocuments),
    ]),
  ),
);

// preparePermissionScopes :: UserGroupAccessItem -> [String]
export const preparePermissionScopes = R.compose(
  R.map(prepareScopeValue),
  R.flatten,
  R.map(R.last),
  R.toPairs,
  removePropDeep('__typename'),
  R.propOr({}, 'typesUI'),
);
