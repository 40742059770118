import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRouterParams } from 'poly-client-routing';
import { gql, useMutation } from '@apollo/client';
import { Icon, Text, Loader } from 'poly-book-admin';

import { useAuthUserByTemporalAccess } from '../DocumentsRequest/DocumentsRequestPage.js';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
`;

const SuccessConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 325px;
  background-color: #ffffff;
  padding: 20px;
`;

const TextS = styled(Text)`
  justify-content: center;
  text-align: center;
  margin: 0 0 10px 0;
`;

const COMPLETE_CONFIRM_SATISFACTION_TASK_MUTATION = gql`
  mutation COMPLETE_CONFIRM_SATISFACTION_TASK_MUTATION($randomId: ID!) {
    completeConfirmSatisfactionTask(randomId: $randomId) {
      _id
    }
  }
`;

export function CompleteConfirmSatisfactionPage() {
  const { randomId } = useRouterParams(['randomId']);
  const [loading, setLoading] = useState(true);
  const [completeConfirmSatisfactionTask] = useMutation(
    COMPLETE_CONFIRM_SATISFACTION_TASK_MUTATION,
  );

  const { isAuthorized } = useAuthUserByTemporalAccess();

  useEffect(() => {
    const runMutation = async () => {
      await completeConfirmSatisfactionTask({ variables: { randomId } });

      setLoading(false);
    };

    if (loading && isAuthorized) {
      runMutation();
    }
  }, [loading, isAuthorized]);

  if (!isAuthorized) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <SuccessConfirmationWrapper>
        <Icon name="successCheckMark" color="white" size={94} />
        <TextS size="22px" weight="bold">
          Thank you for your feedback
        </TextS>
        <TextS size="16px">
          We appreciate you taking the time to share your thoughts with us. Your
          feedback is invaluable as we continuously strive to improve Poly and
          provide the best possible experience for you and your family.
        </TextS>
      </SuccessConfirmationWrapper>
    </PageWrapper>
  );
}
