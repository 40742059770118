import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';

// eslint-disable-next-line import/no-unused-modules
export const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    user {
      _id
      email
      fullName
      firstName
      accessRole {
        _id
        name
      }
      userGroups {
        userGroup {
          _id
          name
          defaultLoginApp
        }
      }
      accessItems {
        permission
        types {
          clientId {
            equals
            flippedContains
          }
          accessRoleId {
            equals
            flippedContains
          }
          userGroupId {
            equals
            flippedContains
          }
          privateSupplier {
            equals
          }
          poAmount {
            gte
          }
        }
      }
    }
  }
`;

export const useCurrentUserQuery = (query, queryOptions = {}) => {
  const { data, loading } = useQuery(query || CURRENT_USER_QUERY, {
    fetchPolicy: 'network-only',
    ...queryOptions,
  });

  return {
    loading,
    user: R.propOr({}, 'user', data),
  };
};
