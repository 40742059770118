import { gql } from '@apollo/client';
import { commonUpdateFields } from '../../../hocs/updates/fragments.js';
import { commonAddressFields } from '../../../hocs/commonFragments.js';

export const staffUserAccountFields = gql`
  fragment staffUserAccountFields on User {
    _id
    status
    email
    companyLocation
    accessRole {
      createdAt
      name
      _id
    }
    userGroups {
      userGroup {
        _id
        name
      }
    }
    profile {
      firstName
      lastName
      fullName
      middleName
      gender
      personalEmail
      personalPhone
      personalMobile
      maritalStatus
      dateOfBirth
      socialSecurityNumber
      loginCellPhoneNumber
      address {
        ...commonAddressFields
      }
      addressTwo
      cellPhoneNumber
      workPhoneNumber
      workPhoneNumberExt
      faxPhoneNumber
    }
    employeeInfo {
      status
      startDate
      terminationDate
      payRateType
      payRate
    }
    remarks
    emails {
      address
    }
    title
    isContractor
    twoFactorAuth {
      isVerified
      createdAt
    }
  }
  ${commonAddressFields}
`;

export const STAFF_USER_UPDATES = gql`
  query STAFF_USER_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    user(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;
