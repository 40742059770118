import * as R from 'ramda';
import { getTimezoneByDate, formatDateMongo } from './dates.js';

/*
  ElasticDateQueryMatcher = Object
  f.e. { gt: '01/01/2017', time_zone: '+0300' }

  ElasticRangeQuery = Object
  f.e. { range: { endDate: ElasticDateQueryMatcher } }
*/

// buildDateMatcher :: [String] -> Date -> ElasticDateQueryMatcher
const buildDateMatcher = (comparators) =>
  R.converge(R.reduce, [
    R.compose(
      (dateStr) => (acc, field) => R.assoc(field, dateStr, acc),
      formatDateMongo,
    ),
    R.compose(R.objOf('time_zone'), getTimezoneByDate),
    R.always(comparators),
  ]);

// dateEq :: Date -> ElasticDateQueryMatcher
const dateEq = buildDateMatcher(['gte', 'lte']);

// dateGte :: Date -> ElasticDateQueryMatcher
const dateGte = buildDateMatcher(['gte']);

// dateLt :: Date -> ElasticDateQueryMatcher
const dateLt = buildDateMatcher(['lt']);

// buildQueryForDateField :: (Date -> ElasticDateQueryMatcher) -> String -> Date -> ElasticRangeQuery
const buildQueryForDateField = (dateMatcher) =>
  R.curry((field, date) => ({
    range: {
      [field]: dateMatcher(date),
    },
  }));

// dateFieldEq :: String -> Date -> ElasticRangeQuery
export const dateFieldEq = buildQueryForDateField(dateEq);

// dateFieldGte :: String -> Date -> ElasticRangeQuery
export const dateFieldGte = buildQueryForDateField(dateGte);

// dateFieldLt :: String -> Date -> ElasticRangeQuery
export const dateFieldLt = buildQueryForDateField(dateLt);
