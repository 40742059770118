import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { NOTHING_UI_STRING } from 'poly-constants';

const UserGroupAITypeUIStringValueFragment = gql`
  fragment UserGroupAITypeUIStringValueFragment on UserGroupAITypeUIStringValue {
    scope
    values
    operation
    variable {
      id
      name
    }
  }
`;

const USER_GROUP_WITH_TYPES_UI_QUERY = gql`
  query USER_GROUP_WITH_TYPES_UI_QUERY($id: ID!) {
    userGroup(id: $id) {
      _id
      name
      accessItems {
        uid
        permission
        typesUI {
          clientId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              name
            }
          }
          assetId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              displayName
            }
          }
          createdBy {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              profile {
                fullName
              }
            }
          }
          contactId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              profile {
                fullName
              }
            }
          }
          technicianId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              profile {
                fullName
              }
            }
          }
          projectId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              projectId
            }
          }
          supplierId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              company {
                name
              }
            }
          }
          propertyId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              name
            }
          }
          accessRoleId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              name
            }
          }
          userGroupId {
            scope
            operation
            variable {
              id
              name
            }
            documents {
              _id
              name
            }
          }
          poAmount {
            scope
            values
            operation
            variable {
              id
              name
            }
          }
          clientApp {
            ...UserGroupAITypeUIStringValueFragment
          }
          updateType {
            ...UserGroupAITypeUIStringValueFragment
          }
          projectType {
            ...UserGroupAITypeUIStringValueFragment
          }
          parentProjectType {
            ...UserGroupAITypeUIStringValueFragment
          }
          supplierSource {
            ...UserGroupAITypeUIStringValueFragment
          }
          fileCollection {
            ...UserGroupAITypeUIStringValueFragment
          }
        }
      }
    }
  }

  ${UserGroupAITypeUIStringValueFragment}
`;

// prepareUserGroupWithTypesUI :: { userGroup: UserGroup } -> [UserGroupAccessItemTypesUI]
const prepareUserGroupWithTypesUI = R.compose(
  R.map(R.over(R.lensProp('typesUI'), R.reject(R.isEmpty))),
  R.pathOr([], ['userGroup', 'accessItems']),
);

// getUserGroupName :: { userGroup: UserGroup } -> String
const getUserGroupName = R.pathOr(NOTHING_UI_STRING, ['userGroup', 'name']);

export const useUserGroupWithTypesUIQuery = (userGroupId) => {
  const { data, loading } = useQuery(USER_GROUP_WITH_TYPES_UI_QUERY, {
    variables: { id: userGroupId },
  });

  const userGroupTypesUI = prepareUserGroupWithTypesUI(data);

  return { userGroupTypesUI, loading, userGroupName: getUserGroupName(data) };
};
