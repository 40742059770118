import {
  PO_AMOUNT_AIT,
  CLIENT_ID_AIT,
  CLIENT_APP_AIT,
  CREATED_BY_AIT,
  CONTACT_ID_AIT,
  PROJECT_ID_AIT,
  PROPERTY_ID_AIT,
  SUPPLIER_ID_AIT,
  UPDATE_TYPE_AIT,
  PROJECT_TYPE_AIT,
  TECHNICIAN_ID_AIT,
  USER_GROUP_ID_AIT,
  ACCESS_ROLE_ID_AIT,
  FILE_COLLECTION_AIT,
  SUPPLIER_SOURCE_AIT,
  PARENT_PROJECT_TYPE_AIT,
} from 'poly-security';

export const addUserGroupFormId = 'addUserGroupFormId';
export const editUserGroupFormId = 'editUserGroupFormId';
export const userGroupAssignUserFormId = 'userGroupAssignUserFormId';

export const userGroupVariablesMap = {
  [CLIENT_ID_AIT]: 'client',
  [CONTACT_ID_AIT]: 'contact',
  [PROJECT_ID_AIT]: 'project',
  [PO_AMOUNT_AIT]: 'po amount',
  [PROPERTY_ID_AIT]: 'property',
  [SUPPLIER_ID_AIT]: 'supplier',
  [CLIENT_APP_AIT]: 'client app',
  [CREATED_BY_AIT]: 'created by',
  [UPDATE_TYPE_AIT]: 'update type',
  [PROJECT_TYPE_AIT]: 'project type',
  [TECHNICIAN_ID_AIT]: 'technician',
  [USER_GROUP_ID_AIT]: 'user group',
  [ACCESS_ROLE_ID_AIT]: 'access role',
  [SUPPLIER_SOURCE_AIT]: 'supplier source',
  [FILE_COLLECTION_AIT]: 'file collection',
  [PARENT_PROJECT_TYPE_AIT]: 'parent project type',
};

export const userGroupScopeAddVariableOptionId =
  'userGroupScopeAddVariableOptionId';
